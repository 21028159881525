.components-comp{
	.selection-1{
		
	}
  .subtitle-left {
    text-indent: 0 !important;
  }
  .subcontent-ul {
    font-size: 0.14rem;
    font-weight: 300;
    list-style: circle;
    padding: 0 0.4rem 0 0.5rem;
    margin-bottom: 20px;
    li {
      margin-bottom: 0.06rem;
    }
  }
}
