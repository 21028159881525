.components-team {
  .selection-1 {
    .news {
      padding: 0 0.2rem;
      margin-bottom: 0.3rem;
      li {
        display: flex;
        background: #fff;
        border: 1px solid #eee;
        padding: 0.15rem;
        border-radius: 6px;
        margin-bottom: 0.15rem;
        .left-img {
          width: 0.8rem;
          margin-right: 0.15rem;
        }
        .right-content {
          flex: 1;
          font-size: 0.14rem;
          color: #666;
        }
      }
    }
  }
  .filter-teacher {
    padding: 0.2rem 0.4rem;
    font-weight: 300;
    line-height: 0.26rem;
    h2.title {
      border-left: 2px solid #b1292b;
      line-height: 16px;
      font-size: 16px;
      padding-left: 5px;
      margin-bottom: 7px;
      text-align: left;
      font-weight: 600;
    }
    .company-img {
      margin-bottom: 30px;
      text-align: right;
      img {
        width: 50px;
      }
    }
  }
}
