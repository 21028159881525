@import "./icon";
@import "./normalize";

.container {
    //padding: 0 .16rem;
}

iframe {
  pointer-events: none;
}

.loader ,.scroll-loader {
    padding-top: .20rem;
    text-align: center;
    color: #999;
    font-size: 0.12rem;
    padding-bottom: .2rem;
}

h3.block-title {
    padding-left: .08rem;
    border-left: 2px solid $color-primary;
    line-height: .15rem;
    font-weight: normal;
    font-size: 0.16rem;
}
.seperator-line {
    position: relative;
    padding-bottom: .05rem;
    border-bottom: 1px solid #EEE;
    h3.block-title {
        margin-bottom: 0;
    }
}
.none{
  margin-top: .58rem;
  text-align: center;
  color: #9b9b9b;
  font-size: .14rem;
  list-style: none;
}
.order-switch{
  margin-top: .07rem;
  padding: 0 .16rem;
  text-align: right;
  color: #999;
  a{
    display: inline-block;
    color: #999;
    font-size: .12rem;
    letter-spacing: .0012rem;
    padding: 0 .04rem 0 .04rem;
    height: .12rem;
    line-height: .12rem;
    border-right: 2px solid #cdcdcd;
  }
  a:last-of-type{
    padding: 0 0 0 .04rem;
    border-right: 0;
  }
  .active{
    color: #fc9153;
  }
}

.end-loader{
  height: .5rem;
  text-align: center;
  line-height: .5rem;
  border-top:2px solid linear-gradient(to top,#F9F9F9,#fff);
  border-radius:.02rem;
  font-size: .14rem;
  color: #9b9b9b;
}

.ptr-element {
	position: absolute;
	top: .83rem;
	left: 0;
	width: 100%;
	color: #aaa;
	z-index: 10;
	text-align: center;
	height: .50rem;
}

.ptr-element .genericon {
	opacity: .6;
	font-size: .34rem;
	width: auto;
	height: auto;
	transition: all .25s ease;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	margin-top: .05rem;
}
.ptr-refresh .ptr-element .genericon {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}
.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
	display: none;
}

.loading {
	display: inline-block;
	text-align: center;
	opacity: .4;
	margin: .12rem 0 0 .05rem;
	display: none;
}
.ptr-loading .loading {
	display: block;
}

.loading span {
	display: inline-block;
	vertical-align: middle;
	width: .10rem;
	height: .10rem;
	margin-right: .03rem;
	-webkit-transform: scale(0.3);
	transform: scale(0.3);
	border-radius: 50%;
	-webkit-animation: ptr-loading 0.4s infinite alternate;
	animation: ptr-loading 0.4s infinite alternate;
}

.loading-ptr-1 {
	-webkit-animation-delay: 0;
	animation-delay: 0 !important;
}

.loading-ptr-2 {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s !important;
}

.loading-ptr-3 {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s !important;
}

@-webkit-keyframes ptr-loading {
	0% {
		-webkit-transform: translateY(0) scale(0.3);
		transform: translateY(0) scale(0.3);
		opacity: 0;
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		background-color: #333;
		opacity: 1;
	}
}

@keyframes ptr-loading {
	0% {
		-webkit-transform: translateY(0) scale(0.3);
		transform: translateY(0) scale(0.3);
		opacity: 0;
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		background-color: #333;
		opacity: 1;
	}
}

.ptr-loading .refresh-view, .ptr-reset .refresh-view,
.ptr-loading .ptr-element, .ptr-reset .ptr-element {
	transition: all .25s ease;
}

.ptr-reset .refresh-view {
	-webkit-transform: translate3d( 0, 0, 0 );
	transform: translate3d( 0, 0, 0 );
}

.ptr-loading .refresh-view {
	-webkit-transform: translate3d( 0, 30px, 0 );
	transform: translate3d( 0, 30px, 0 );
}

.atwho-view{
    position: fixed;
    // top: 0;
    // left: 0;
    display: none;
    margin-top: .18rem;
    max-height:1.8rem;
    width: 1.5rem;
    background:#ffffff;
    border:1px solid #dddddd;
    border-radius:4px;
    overflow-y: auto;
    z-index: 11110 !important;
    .atwho-header:hover {
        cursor: default;
    }
    .cur {
        background: rgba(252,145,83,0.14);
    }
    ul{
        width: 100%;
        list-style-type: none;
       -webkit-margin-before: 0;
       -webkit-margin-after: 0;
       -webkit-margin-start: 0;
       -webkit-margin-end: 0;
       -webkit-padding-start: 0;

        li{
            width: 1.4rem;
            padding-left: .1rem;
            padding-top: .05rem;
            height: .3rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border-top:1px solid #dddddd;
            &:first-of-type{
                border-top:none;
            }
            .imgBox{
                display: inline-block;
                line-height: .3rem;
                width: .21rem;
                height: .21rem;
                border-radius: 100%;
                overflow: hidden;
                vertical-align:middle;
                img{
                    vertical-align:top;
                }
            }
            .name{
                font-size:.14rem;
                color:#333333;
                margin: 0 .05rem 0 .05rem;

            }
            .mail{
                font-size:.12rem;
                color:#A8A8A8;
            }
            &:hover{
                background:rgba(252,145,83,0.14);
            }
        }
    }
}

/* react-pull-to-refresh CSS */

// #app {
// 	// background-color: #fff;
// 	min-height: 100%;
// 	z-index: 20;
// 	-webkit-backface-visibility: hidden;
// 	-webkit-perspective: 1000;
// 	padding: 0 .16rem;
// 	// text-align: center;
// 	// font-family: Helvetica, Arial, sans-serif;
// 	// font-size: 24px;
// 	// font-weight: 200;
// 	// color: #555;
// 	// box-sizing: border-box;
// }

// #app h3 {
// 	// margin: 0.4rem;
// }
//
// body:not(.ptr-loading) #app .ptr-element {
// 	transform: translate3d(0, -.83rem, 0);
// }
