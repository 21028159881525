.page-user-edit {
	background: url(../../assets/img/demo/background.jpg);
	background-size: 100%;
  overflow: hidden;
	.page-header{
    position: relative;
    overflow: hidden;
    height: 1.5rem;
    background: #ccc;
    margin-bottom: 0.15rem;
    background: url(../../assets/img/banners/city2.jpeg);
    background-size: auto 100%;
    background-position: center center;
    .edit-title {
      margin-top: 0.25rem;
      font-size: 0.3rem;
      color: #fff;
      text-align: center;
      font-weight: 300;
      text-shadow: 0 1px 0.1rem rgba(0, 0, 0, 0.3);
    }
    .user-photo {
      float: left;
      margin-left: 0.24rem;
      margin-top: 0.35rem;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 100%;
      background: url(../../assets/img/git-head.jpeg);
      background-size: 100% 100%;
    }
    .user-name {
      float: left;
      text-shadow: 0 1px 20px #000;
      margin-top: 0.35rem;
      font-size: 0.24rem;
      font-weight: 400;
      line-height: 0.7rem;
      color: #fff;
      margin-left: 0.15rem;
    }
	}
	p{
		margin: 0;
  }
  .privacy{
		background: #2D2D2D;
		text-align: center;
		color: #9B9B9B;
		font-size: 0.12rem;
		line-height: 0.4rem;
		padding-top: 0.4rem;
  }
  .goto-edit {
    margin-top: 0.5rem;
    text-align: right;
    a {
      color: #a00022;
    }
  }
  .nav{
    position: absolute;
		list-style-type: none;
    padding: 0;
    left: 0;
    right: 0;
    bottom: 0.02rem;
    display: flex;
		li{
			flex: 1;
			text-align: center;
			font-size: 0.12rem;
			line-height: 0.225rem;
			font-weight: 200;
			display: inline-block;
			span{
				color: #C6C6C6;
			}
			a{
				width: 100%;
				display: inline-block;
			}
			&.active{
				border-bottom: 0.015rem #fff solid;
				span{
					color: #fff;
				}
			}
		}
	}
}