.components-contact{
	p{
		padding: 0 0.4rem;
		line-height: 0.24rem;
	}
	.map-img{
	 	width: 80%;
	 	margin: 0rem auto 0.3rem;
	 	display: block;
	}
	.subtitle2{
		text-align: center;
		font-size: 0.15rem;
	    font-weight: 300;
	    margin-bottom: 0.4rem;
	}
}