@import '../../../assets/css/variable';

$color-border: #DDD;
$color-meta: #666;
$color-close: #999;

.imodal-module {
    position: relative;
    .imodal-header {
        padding-bottom: 20px;
        .imodal-close {
            float: right;
            color: $color-close;
            font-size: 0.12rem;
        }
        .title {
            margin: 0;
            font-size: 0.14rem;
            text-indent: 1em;
        }
        .title1{
          font-size: .12rem;
          text-indent: 3em;
          line-height: .3rem;
        }
    }

    .imodal-body {
        color: $color-meta;
        font-size: 0.12rem;

        input {
            padding: 10px 6px;
            width: 100%;
            border: 1px solid $color-border;
            border-radius: 4px;
            font-size: 0.12rem;
            box-sizing: border-box;
        }
    }

    .imodal-footer {
        margin-top: 20px;
        text-align: right;

        .imodal-msg {
            margin-right: 8px;
            color: $color-primary;
            font-size: 0.12rem;
        }

        button {
            padding: 6px 16px;
            border-radius: 6px;
            border: 1px solid $color-border;
            background: #FFF;
            font-size: 0.14rem;
        }

        .btn-ok {
            margin-left: 8px;
            background: $color-primary;
            border: 1px solid $color-primary;
            color: #FFF;
        }
    }
}

.groupModal{
    margin: 0 auto;
    width: 100%;
    background-color: #fff;
    border-radius: .16rem;
    margin-top: -.2rem;
    .groupLogo{
        width: .5rem;
        height: .5rem;
        overflow: hidden;
        margin: 0 auto;
        img{
            width: 100%;
            height: 100%;
          border-radius: 100%;
        }
    }
    .tip{
        height: .21rem;
        width: 100%;
        text-align: center;
        line-height: .21rem;
        font-size: .18rem;
        letter-spacing: .0029rem;
        color: #333;
        margin: .09rem 0 .23rem 0;
    }
   .btn{
       height: .3rem;
       display: box;
       display: -webkit-flex;
       display: flex;
       -webkit-justify-content: space-between;
       justify-content: space-between;
       margin-bottom: .05rem;
       padding: 0 .36rem;
       span{
           display: inline-block;
           width: .7rem;
           height: .28rem;
           border: 1px solid #fc9153;
           border-radius: .5rem;
           background-color: #fff;
           text-align: center;
           line-height: .28rem;
           font-size: .15rem;
           letter-spacing: .0026rem;
           color:#fc9153;
       }
       .btn-ok{
           color: #fff;
           background-color: #fc9153;
       }
   }
}
