.user-prof {
  padding: 0 0.2rem 0.3rem;
  .user-list {
    border-bottom: 1px solid #ccc;
    line-height: 0.5rem;
    display: box;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    .user-label {
      width: 1rem;
      text-align: left;
    }
    .user-detail {
      flex: 1;
      text-align: right;
    }
  }
  .ta-right {
    text-align: right;
  }
}
.user-skill {
  border-bottom: 1px solid #ccc;
  .user-label {
    line-height: 0.5rem;
  }
  .user-info {
    font-size: 0.12rem;
    line-height: 0.24rem;
    .data-item {
      display: inline-block;
      padding: 0 10px;
      margin-right: 7px;
      margin-bottom: 7px;
      background: rgb(117, 172, 221);
      border-radius: 3px;
      color: #fff;
    }
  }
  .nodata {
    text-align: center;
    color: #ccc;
    font-size: 0.12rem;
    margin-bottom: 5px;
  }
}
.user-school,
.user-company {
  border-bottom: 1px solid #ccc;
  li {
    border-radius: 0.04rem;
    border: 1px solid #ccc;
    padding: 0.1rem;
    margin-bottom: 0.1rem;
  }
  .user-label {
    line-height: 0.5rem;
  }
  .user-info {
    display: flex;
    font-size: 0.12rem;
    line-height: 0.24rem;
  }
  .user-right {
    margin-left: 0.1rem;
    text-align: right;
  }
  .user-left {
    flex: 1;
  }
  .nodata {
    text-align: center;
    color: #ccc;
    font-size: 0.12rem;
    margin-bottom: 5px;
  }
}
.add-company,
.add-school {
  border: 1px solid #ccc;
  border-radius: 0.03rem;
  height: 0.3rem;
  line-height: 0.3rem;
  font-size: 0.14rem;
  text-align: center;
  margin-bottom: 0.3rem;
  margin-top: 0.2rem;
}