.page-join{
	background: #fff;
	.join-banner{
		position: relative;
	    padding-top: 0.25rem;
	    width: 100%;
	    height: 0.95rem;
	    background: url(../../assets/img/help/join.jpg) center;
		background-size: 100%;
		font-size: 0.3rem;
		color: #fff;
		text-align: center;
		font-weight: 300;
		.sub-title{
			font-size: 0.14rem;
			line-height: 0.3rem;
			color: #C6C6C6;
		}
	}
	.feature-img{
		height: 1.95rem;
		width: 100%;
		margin-bottom: 0.3rem;
	}
	.main-title{
		font-size: 0.24rem;
		margin-bottom: 0.05rem;
		padding-bottom: 0.23rem;
	}
	p{
		margin: 0;
	}
	.privacy{
		background: #2D2D2D;
		text-align: center;
		color: #9B9B9B;
		font-size: 0.12rem;
		line-height: 0.4rem;
		padding-top: 0.4rem;
	}
	.free-week{
		padding: 0.38rem 0 0;
		background: url(../../assets/img/bottom-stars.png) no-repeat #2D2D2D;
		background-size: 100%;
		color: #fff;
		height: 2.22rem;
		.btn-demo{
			width: 1.2rem;
			height: 0.375rem;
			line-height: 0.375rem;
			border-radius: 0.03rem;
			font-size: 0.15rem;
			color: #b1292b;
			background: #fff;
			margin: 0.25rem auto 0;
			text-align: center;
		}
		.main-title{
			text-align: center;
			font-weight: 600;
			font-size: 0.34rem;
			i{
				display: inline-block;
				background: url(../../assets/img/feature/house.png) no-repeat;
				background-size: 0.25rem;
				margin-right: 0.16rem;
				width: 0.25rem;
				height: 0.21rem;
			}
		}
		.second-title{
			text-align: center;
			font-weight: 300;
			font-size: 0.1rem;
		}
		p{
			margin: 0;
			padding: 0;
		}
	}
	.child-solution{
		.selection-1{
			background: rgba(247,247,247,1);
			padding-top: 0.3rem;
			overflow: hidden;
		}
		.selection-2{
			background: #fff;
			overflow: hidden;
			padding-bottom: 0.12rem;
		}
		.selection-3{
			background: #fff;
			overflow: hidden;
			padding-bottom: 0.12rem;
		}
		.title{
			margin-bottom: 0.3rem;
			font-size: 0.24rem;
			text-align: center;
			font-weight: 300;
			padding: 0 0.52rem;
		}
		.subtitle{
			margin-bottom: 0.18rem;
			font-size: 0.16rem;
			font-weight: 400;
			margin-top: -0.15rem;
			line-height: 0.26rem;
			padding-top: 0.2rem;
			text-indent: 0.25rem;
		}
	}
	.components-join{
		ul{
			margin-bottom: 0.18rem;
		    font-size: 0.15rem;
		    text-align: left;
		    font-weight: 300;
		    padding: 0 0.4rem 0 0.5rem;
		    line-height: 0.26rem;
		    list-style: decimal;
			li{
			}
		}
		.subtitle2{
			padding: 0 0.4rem;
			line-height: 0.26rem;
			font-weight: 300;
			margin-bottom: 0.4rem;
		}
	}
}
