$color-default:             #4A4A4A;
$color-primary:             #FC9153;
$color-primary-hover:       #FB8038;
$color-bg:                  none;

$font-family:            "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", "Microsoft Yahei", 微软雅黑, STHeiti, 华文细黑, sans-serif;

$font-size-base:            14px !default;
$font-size-small:           12px !default;
$font-size-middle:          16px !default;
$font-size-large:           18px !default;


$width-container:           1000px;
