.x-icon {
    display: inline-block;
    vertical-align: middle;
    //background-image: url(../img/app-sprites.png);
    background-size: 2.5rem .8rem;

    &.x-icon-stat-view {
        width: .13rem;
        height: .12rem;
        background-position: -.02rem -.04rem;
    }

    &.x-icon-stat-circle {
        width: .125rem;
        height: .125rem;
        background-position: -.02rem -.23rem;
    }

    &.x-icon-stat-close {
        width: .11rem;
        height: .11rem;
        background-position: -.52rem -.37rem;
    }

    &.x-icon-stat-line {
        width: 1.02rem;
        height: .01rem;
        background-position: -.06rem -.6rem;
    }

    &.x-icon-stat-code {
        width: .26rem;
        height: .26rem;
        background-position: -.18rem -.22rem;
    }

    &.x-icon-stat-comment {
        width: .15rem;
        height: .14rem;
        background-position: -.2rem -.03rem;
    }

    &.x-icon-stat-bonus {
        width: .15rem;
        height: .14rem;
        background-position: -.55rem -.02rem;
    }

    &.x-icon-search {
        width: .13rem;
        height: .13rem;
        background-position: -.37rem -.02rem;
    }

    &.x-icon-caret {
        width: .05rem;
        height: .04rem;
        background-position: -.92rem -.18rem;
    }

    &.x-icon-stat {
        width: .13rem;
        height: .12rem;
        background-position: -.73rem -.02rem;
    }

    &.x-icon-lg-star {
        width: .2rem;
        height: .20rem;
        background-position: -1.04rem -.02rem;
        &.active{
            background-position: -1.04rem -.33rem;
        }

    }
    &.x-icon-lg-up {
        width: .20rem;
        height: .20rem;
        background-position: -1.28rem -.03rem;
        &.active{
            background-position: -1.28rem -.32rem;
        }
    }
    &.x-icon-lg-edit {
        width: .19rem;
        height: .17rem;
        background-position: -1.53rem -.05rem;
    }
    &.x-icon-lg-share {
        width: .19rem;
        height: .19rem;
        background-position: -1.78rem -.04rem;
    }
    &.x-icon-lg-comment {
        width: .19rem;
        height: .19rem;
        background-position: -2.02rem -.33rem;
        &.active{
            background-position: -2.02rem -.03rem;
        }
    }
    &.x-icon-sm-up{
        width: .11rem;
        height: .11rem;
        background-position: -2.26rem -.07rem;
    }
    &.x-icon-group-write{
        width: .09rem;
        height: .09rem;
        background-position: -.04rem -.38rem;
    }

    &.x-icon-scroll-top {
        width: .17rem;
        height: .12rem;
        background-position: -.69rem -.36rem;
    }
}

a:hover, .active {
    // 圈子文章icon
    .x-icon-caret {
        background-position: -.92rem -.06rem;
    }
    .x-icon-lg-star {
        background-position: -1.04rem -.33rem;
    }
    .x-icon-lg-up {
        background-position: -1.28rem -.33rem;
    }
    .x-icon-lg-comment-full {
        background-position: -2.02rem -.03rem;
    }
}
