@import './variable';

html{
  font-size: 100px;
  line-height: normal;
}
html, body {
    height: 100%;
    // font-size: 100px;
    -webkit-tap-highlight-color: transparent;
}

// @media screen and (min-width: 320px) {
//     html {
//         font-size: 85.333333px;
//     }
// }
//
// @media screen and (min-width: 370px) {
//     html {
//         font-size: 100px;
//     }
// }

@media screen and (min-width: 960px) {
    html {font-size: 117.33px !important; background: #7a7a7a;}
    body {width: 440px; box-shadow: 3px 0 7px rgba(0,0,0,0.4); margin: 0 auto!important;}
}

body {
    margin: 0;
    color: $color-default;
    background: $color-bg;
    font-family: $font-family;
    font-size: 0.16rem;
}

a {
    color: $color-default;
    text-decoration: none;
    transition: color .3s ease;

    &:link, &:visited, &:hover, &:active {
        text-decoration: none;
        color: $color-default;
    }
}

img {
    max-width: 100%;
}
textarea{
    resize:none;
    outline:none;
    border: none;
}
button{
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
}
ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
