@import '../../../assets/css/variable';

.loading-module {
    position: relative;

    .loading-body {
        font-size: 0.12rem;
        color: #FFF;
        text-align: center;
    }
}
