.components-qa{
  padding: 0.3rem;
	ul{
		margin: 0.3rem 0;
		padding: 0 0.4rem;
		line-height: 0.24rem;
	}
	.qa-title{
		font-size: 0.18rem;
		margin: 0 0 0.1rem;
		color: #b1292b;
	}
	.qa-dest{
		font-size: 0.14rem;
		margin-bottom: 0.24rem;
	}
	.big-dest-text{
		font-size: 0.18rem;
		color: #b1292b;
	}
  .subtitle {
    padding: 0.15rem;
    background-color: #f7f7f7;
    border: 1px solid #eee;
    margin-bottom: 0.15rem;
    border-radius: 6px;
    font-size: 0.14rem;
    color: #666;
  }
}