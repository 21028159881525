@import '../../../assets/css/variable';

.header {
    z-index: 1;
}

.popup-back{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.header-module {
    height: 0.6rem;
    padding: 0 0.15rem;
    display: box;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    .menu{
        float: left;
        width: 0.6rem;
        .menu-logo {
            display: inline-block;
            width: 0.165rem;
            height: 0.15rem;
            background: url(../../../assets/img/menu.png);
            background-size: 0.165rem;
        }
    }
    .logo{
        -moz-box-flex: 1;
        -webkit-box-flex: 1;
        width: 1rem;
        height: 0.3rem;
        background: url(../../../assets/img/logo.png) no-repeat;
        background-size: auto 0.3rem;
        margin: 0 auto;

    }
    .lang {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        font-size: 0.14rem;
        color: #666;
        font-weight: 400;
        float: right;
        width: 0.6rem;
        text-align: right;
        .lang-logo{
            display: inline-block;
            margin-right: 0.07rem;
            width: 0.12rem;
            height: 0.125rem;
            background: url(../../../assets/img/lang.png);
            background-size: 0.12rem;
        }
    }

    &.black{
        background: #333;
        .logo{
            background: url(../../../assets/img/logo.png) no-repeat;
            background-size: 0.615rem;

        }
        .lang {
            color: #979797;
        }
    }
    &.white{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        border-bottom: 0.01rem solid #E0E0E0;
        .logo{
            background: url(../../../assets/img/logo2.png) no-repeat;
            background-size: auto 0.3rem;
        }
    }
    .navs {
        margin:0 .28rem 0 .38rem;
        list-style: none;
        padding-left: 0;
        padding-top: .09rem;
        padding-bottom: .09rem;

        li {
            position: relative;
            display: inline-block;
            line-height: .22rem;
            width: 25%;
            text-align: center;
            a {
                font-size: 0.16rem;
                transition: color .3s ease;
                line-height: .22rem;

            }

            &.active {
                a {
                    color: $color-primary;
                    line-height: .22rem;

                }
                &::after {
                    content: ' ';
                    position: absolute;
                    background: $color-primary;
                    width: .41rem;
                    height: 2px;
                    // margin-left: 22%;
                    // top: 100%;
                    // left: 0;
                    // margin-top: 7px;
                    top:0;
                    left:0;
                    right: 0;
                    bottom: -0.39rem;
                    margin: auto;

                }
            }
        }
    }
    .menu-options, .lang-options{
        position: absolute;
        top: 0.45rem;
        right: 0;
        left: 0;
        opacity: 0.98;
    }
    .menu-options, .user-options{
        position: absolute;
        top: 0.6rem;
        right: 0;
        left: 0;
        opacity: 0.98;
    }
}
