.help-banner{
	position: relative;
    padding-top: 0.25rem;
    width: 100%;
    height: 0.95rem;
    background: url(../../../assets/img/help/background.png) center;
	background-size: 100%;
	font-size: 0.3rem;
	color: #fff;
	text-align: center;
	font-weight: 300;
	.nav{
		list-style-type: none;
		padding: 0;
		margin-top: 0.28rem;
		li{
			float: left;
			width: 50%;
			text-align: center;
			font-size: 0.12rem;
			line-height: 0.225rem;
			font-weight: 200;
			display: inline-block;
			span{
				color: #C6C6C6;
			}
			a{
				width: 100%;
				display: inline-block;
			}
			&.active{
				border-bottom: 0.015rem #fff solid;
				span{
					color: #fff;
				}
			}
		}
	}
}