.page-lesson-list{
	.components-lesson-list{
		padding: 0 0.4rem;
		.cate-title{
			margin-bottom: 0.3rem;
		    font-size: 0.24rem;
		    text-align: center;
		    font-weight: 300;
		    padding: 0 0.52rem;
		}
		.lesson-li{
			width: 100%;
			height: 1.2rem;
			border-radius: 0.05rem;
			border: 1px solid #878787;
			margin-bottom: 0.3rem;
			box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
			p{
				color: #fff;
				line-height: 1.2rem;
				text-align: center;
				padding: 0;
				margin: 0;
				font-size: 0.22rem;
				text-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
			}
			&.fe{
				background: url(../../assets/img/lesson/1.jpg);
				background-size: 100%;
			}
			&.rd{
				background: url(../../assets/img/lesson/2.jpg);
				background-size: 100%;
			}
			&.php{
				background: url(../../assets/img/lesson/3.jpg);
				background-size: 100%;
			}
			&.java{
				background: url(../../assets/img/lesson/4.jpg);
				background-size: 100%;
			}
			&.react{
				background: url(../../assets/img/lesson/1.jpg);
				background-size: 100%;
			}
			&.vue{
				background: url(../../assets/img/lesson/2.jpg);
				background-size: 100%;
			}
			&.mentor{
				background: url(../../assets/img/lesson/5.jpg);
				background-size: 100%;
			}
		}
	}
	.privacy{
		background: #2D2D2D;
		text-align: center;
		color: #9B9B9B;
		font-size: 0.12rem;
		line-height: 0.4rem;
		padding-top: 0.4rem;
	}
}