@import '../../../assets/css/variable';

.not-found-module {
    width: 100%;
    height: 100vh;
    display: box;
    display: flex;
    background: url(../../../assets/img/demo/background.jpg);
	background-size: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .p-404 {
        color: #b1292b;
        text-align: center;
        font-size: 0.88rem;
        font-weight: 500;
    }
    .goback {
        font-size: 0.16rem;
        color: #333;
        a {
           color: #b1292b;
           font-weight: 500; 
        }
    }
}
