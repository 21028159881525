.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transition: top 0.3s ease;
  z-index: 100;

  &.active {
    top: -100%;
  }
}

.main {
  padding-top: 0.6rem;
}

.privacy{
  background: #2D2D2D;
  text-align: center;
  color: #9B9B9B;
  font-size: 0.12rem;
  line-height: 0.4rem;
  padding-top: 0.4rem;
}
