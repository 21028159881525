.user-order {
  padding-bottom: 0.3rem;
  ul {
    padding-left: 0.2rem;
    li.order-list {
      padding: 0.26rem 0;
      border-bottom: 1px solid #ccc;
      .order-title {
        font-size: 0.16rem;
        font-weight: 500;
        line-height: 0.3rem;
        color: #333;
      }
      .order-no,
      .order-time {
        font-size: 0.12rem;
        color: #666;
      }
    }
  }
  .nodata {
    font-size: 14px;
    color: #666;
    line-height: 0.5rem;
    text-align: center;
  }
  .order-info {
    display: box;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
  }
  .order-left {
    flex: 1;
    font-size: 0.12rem;
  }
  .order-right {
    padding-right: 0.15rem;
    align-items: center;
    align-self: center;
    font-size: 0.14rem;
    color: #666;
  }
  .notice {
    margin: 0.15rem 0;
    padding: 0.06rem;
    color: #333;
    font-size: 0.12rem;
    line-height: 0.2rem;
    border-radius: 4px;
    border: 1px solid #91caff;
    background-color: #e6f4ff;
  }
}