.page-feature{
	background: #fff;
	.banner{
		position: relative;
	    margin-top: -0.45rem;
	    padding-top: 0.55rem;
	    width: 100%;
	    height: 0.65rem;
	    background: url(../../assets/img/privacy/banner.jpg);
    	background-size: 100%;
    	font-size: 0.27rem;
    	color: #fff;
    	text-align: center;
	}
	.text-box-1{
		padding: 0.33rem 0.23rem;
		font-size: 0.15rem;
	}
	.text-box-2{
		padding: 0.33rem 0.23rem;
		font-size: 0.15rem;
		ul{
			list-style-type: lower-alpha;
			padding-left: 0.18rem;
			li{
				margin-bottom: 0.3rem;
			}
		}
	}
  .main-title{
		font-size: 0.18rem;
    line-height: 0.26rem;
		text-align: center;
    &::after {
      display: block;
      margin: 0.1rem auto;
      content: '';
      width: 20px;
      height: 2px;
      background: #3e6aa3;
    }
	}
	.second-title{
		font-size: 0.12rem;
		text-align: center;
		font-weight: 300;
    margin-bottom: 0.3rem;
	}
  .content-box {
    margin: 0.2rem auto;
    padding: 0.15rem 0.15rem 0.07rem;
    border-radius: 4px;
    background: #f5f5f5;
    .content {
      font-size: 0.14rem;
      line-height: 0.2rem;
      color: #666;
    }
  }
  .content {
    margin-bottom: 0.08rem;
  }
	p{
		margin: 0;
	}
	.child-feature{
		.selection-1{
			background: rgba(247,247,247,1);
			padding-top: 0.38rem;
		}
		.selection-2{
			background: #fff;
			padding-top: 0.3rem;
			padding-bottom: 0.2rem;
			overflow: hidden;
		}
		.selection-3{
			background: #f7f7f7;
			padding-top: 0.38rem;
			padding-bottom: 0.2rem;
			overflow: hidden;
		}
		.title{
			margin-bottom: 0.18rem;
			font-size: 0.24rem;
			text-align: center;
			font-weight: 300;
		}
		.subtitle{
			margin-bottom: 0.18rem;
			font-size: 0.14rem;
			text-align: center;
			font-weight: 300;
			padding: 0 0.35rem;
			line-height: 0.22rem;
		}
		.subtitle2{
			font-size: 0.16rem;
			font-weight: 500;
			padding: 0rem;
			margin-bottom: 0.07rem;
			text-align: left;
		}
		.subcontent2{
			font-size: 0.14rem;
			padding: 0rem;
			margin-bottom: 0.2rem;
			text-align: left;
		}
	}
  article {
    padding: 0.2rem;
    .img-bottom {
      margin-bottom: 0.5rem;
    }
  }
}
