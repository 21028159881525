body {
  line-height: normal;
}
.user-edit-prof {
  padding: 0 0.2rem 0.3rem;
  .user-list {
    border-bottom: 1px solid #ddd;
    line-height: 44px;
    display: box;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    .user-label {
      width: 1rem;
      text-align: left;
    }
    .user-detail {
      flex: 1;
      text-align: right;
      font-size: 0.14rem;
      input {
        width: 100%;
        line-height: 44px;
        border: 0;
        padding: 0;
        text-align: right;
      }
    }
  }
  .ta-right {
    text-align: right;
  }
  .btn-sub-demo {
    margin-top: 0.5rem;
    background: #2B90E9;
    border: 1px solid #2B90E9;
    border-radius: 0.03rem;
    height: 0.45rem;
    line-height: 0.45rem;
    font-size: 0.2rem;
    color:#fff;
    text-align: center;
    font-weight: 300;
    margin-bottom: 0.3rem;
    &.grey{
      border: 1px solid #9b9b9b;
      background: #9b9b9b;
    }
  }
}
.user-skill {
  //border-bottom: 1px solid #ccc;
  .user-label {
    line-height: 0.44rem;
  }
  .user-edit {
    textarea {
      width: 100%;
      height: 1rem;
      padding: 0.1rem 0;
      text-indent: 0.1rem;
      line-height: 0.24rem;
      border: 1px solid #ddd;
    }
  }
  .user-info {
    font-size: 0.12rem;
    line-height: 0.24rem;
    .data-item {
      display: inline-block;
      padding: 0 10px;
      margin-right: 7px;
      margin-bottom: 7px;
      background: rgb(117, 172, 221);
      border-radius: 3px;
      color: #fff;
    }
  }
  .nodata {
    text-align: center;
    color: #ccc;
    font-size: 0.12rem;
    margin-bottom: 5px;
  }
}
.user-school,
.user-company {
  border-bottom: 1px solid #ddd;
  li {
    border-radius: 0.04rem;
    border: 1px solid #ddd;
    padding: 0.1rem;
    margin-bottom: 0.1rem;
  }
  .user-label {
    line-height: 44px;
  }
  .user-info {
    display: box;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
    font-size: 0.12rem;
    line-height: 0.24rem;
  }
  .user-right {
    margin-left: 0.1rem;
    text-align: right;
  }
  .user-left {
    flex: 1;
  }
  .nodata {
    text-align: center;
    color: #ccc;
    font-size: 0.12rem;
    margin-bottom: 5px;
  }
}
.hide {
  display: none;
}
.am-list-item {
  padding: 0;
  font-size: 14px;
  border-bottom: 1px solid #ddd!important;
  .am-list-line {
    padding: 0;
    .am-list-content {
      font-size: 0.16rem;
      color: #4a4a4a;
    }
    .am-list-extra {
      font-size: 0.14rem;
    }
  }
}
.am-list-body::before, .am-list-body::after {
  content: none!important;
}