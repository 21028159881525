@import '../../../assets/css/variable';

.itoastr-module {
    position: relative;

    .itoastr-body {
        font-size: 0.12rem;
        color: #FFF;
        text-align: center;
    }
}
