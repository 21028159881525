.page-user {
	background: url(../../assets/img/demo/background.jpg);
	background-size: 100%;
  overflow: hidden;
	.page-header{
    position: relative;
    overflow: hidden;
    height: 1.5rem;
    background: #ccc;
    margin-bottom: 0.15rem;
    background: url(../../assets/img/banners/city2.jpeg);
    background-size: auto 100%;
    background-position: center center;
    .user-photo {
      float: left;
      margin-left: 0.24rem;
      margin-top: 0.35rem;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 100%;
      background: url(../../assets/img/git-head.jpeg);
      background-size: 100% 100%;
    }
    .user-name {
      float: left;
      text-shadow: 0 1px 20px #000;
      margin-top: 0.35rem;
      font-size: 0.24rem;
      font-weight: 400;
      line-height: 0.7rem;
      color: #fff;
      margin-left: 0.15rem;
    }
    .createQR {
      padding: 0.03rem;
      position: absolute;
      top: 0.15rem;
      font-size: 0.12rem;
      right: 0.1rem;
      background: #2B90E9;
      color: #fff;
      border-radius: 3px;
      border: 1px solid #fff;
    }
	}
	p{
		margin: 0;
  }
  .privacy{
		background: #2D2D2D;
		text-align: center;
		color: #9B9B9B;
		font-size: 0.12rem;
		line-height: 0.4rem;
		padding-top: 0.4rem;
  }
  .goto-edit {
    margin-top: 0.5rem;
    text-align: right;
    a {
      color: #a00022;
    }
  }
  .nav{
    position: absolute;
		list-style-type: none;
    padding: 0;
    left: 0;
    right: 0;
    bottom: 0.02rem;
    display: box;
    display: -webkit-box;
    display: flex;
    display: -webkit-flex;
		li{
			flex: 1;
			text-align: center;
			font-size: 0.14rem;
			line-height: 0.225rem;
			font-weight: 400;
			display: inline-block;
			span{
				color: #C6C6C6;
			}
			a{
				width: 100%;
				display: inline-block;
			}
			&.active{
				border-bottom: 0.015rem #fff solid;
				span{
					color: #fff;
				}
			}
		}
	}
  .logout {
    margin: 0.2rem 0.2rem 0.2rem;
    background: #fff;
    border: 1px solid #a00022;
    border-radius: 0.03rem;
    height: 0.34rem;
    line-height: 0.34rem;
    font-size: 0.16rem;
    color: #a00022;
    text-align: center;
    font-weight: 400;
  }
}