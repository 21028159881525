.child-cont-1{
	padding: 0.38rem 0.3rem;
	ul{
		margin-top: 0.4rem;
		list-style-type: none;
		padding: 0;
		li{
			clear: both;
			display: box;
		    display: -webkit-box;
		    margin-bottom: 0.3rem;
			i{
				display: block;
				width: 0.6rem;
				height: 0.6rem;
				border-radius: 100%;
				// border: 1px solid #878787;
				background-color: #fff;
				&.cont-1-img1{	
					background: url(../../../assets/img/avatar/cont-1-img1.png) center center;
					background-size: auto 0.5rem;
				}
        &.cont-1-img2{	
					background: url(../../../assets/img/avatar/cont-1-img2.jpeg) center center;
					background-size: auto 0.6rem;
				}
        &.cont-1-img3{	
					background: url(../../../assets/img/avatar/cont-1-img3.webp) center center;
					background-size: auto 0.6rem;
				}
        &.cont-1-img4{	
					background: url(../../../assets/img/avatar/cont-1-img4.jpeg) center center;
					background-size: auto 0.6rem;
				}
        &.cont-1-img5{	
					background: url(../../../assets/img/avatar/cont-1-img5.jpg) center center;
					background-size: auto 0.6rem;
				}
			}
			.right-text{
				display: block;
				-moz-box-flex: 1;
    			-webkit-box-flex: 1;
    			margin-left: 0.14rem;
				.title{
    				font-size: 0.16rem;
    				margin-bottom: 0.06rem;
    			}
    			.content{
    				font-size: 0.13rem;
    				font-weight: 300;
            color: #999;
    			}
			}
		}
	}
}