@import "../../../assets/css/variable";

.banner-module {
  position: relative;
  width: 100%;
  img {
    width: 101%;
    max-width: 101%;
  }
  .banner-desc {
    font-size: 0.14rem;
    color: #fff;
    text-align: center;
    line-height: 0.2rem;
    margin: 0.2rem 0 0.15rem;
  }
  .banner-desc2 {
    font-size: 0.14rem;
    color: #fff;
    text-align: center;
    line-height: 0.2rem;
    margin: 0;
    padding: 0 0.3rem;
    font-weight: 200;
  }
  .banner-lang {
    margin: 0 0 0.1rem;
    font-size: 0.24rem;
    color: #fff;
    text-align: center;
    line-height: 0.45rem;
    padding: 0 0.1rem;
    font-weight: 200;
  }
  .btn-demo {
    margin: 0.4rem auto 0;
    background: #fff;
    width: 2.5rem;
    height: 0.43rem;
    line-height: 0.43rem;
    border-radius: 0.03rem;
    font-size: 0.15rem;
    text-align: center;
    color: #333;
  }
}
