.user-edit-company {
  .user-company {
    border-bottom: 0px;
  }
  .company-edit {
    background: #fff;
    padding: 0.07rem 0.15rem 0.15rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    overflow: hidden;
    margin-bottom: 0.1rem;
  }
  .btn-box {
    // padding: 0.15rem 0 0;
    display: flex;
    .btn-sub-demo {
      margin-top: 0.2rem;
      border-radius: 0.03rem;
      height: 0.3rem;
      line-height: 0.3rem;
      font-size: 0.18rem;
      text-align: center;
      font-weight: 300;
      margin-bottom: 0.1rem;
    }
    .btn-add {
      flex: 1;
      background: #2B90E9;
      color: #fff;
    }
    .btn-cancel {
      flex: 1;
      margin-left: 0.1rem;
      background: rgb(215, 235, 253);
      color: #2B90E9;
    }
  }
  .notice {
    text-align: center;
    padding: 0.2rem 0 0.1rem;
    font-size: 0.14rem;
    color: #777;
  }
}
