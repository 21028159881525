.index-nav{
	background: #2D2D2D;
	ul{
		margin: 0;
		padding: 0;
		border-top: 1px solid #4A4A4A;
		list-style-type: none;
		li{
			padding: 0 0.22rem;
			height: 0.39rem;
			line-height: 0.39rem;
			.title{
				color: #D7D7D7;
				font-size: 0.14rem;
			}
			.sub{
				color: #9B9B9B;
				font-size: 0.1rem;
				margin-left: 0.12rem;
			}
			&.end{
				border-bottom: 1px solid #4A4A4A;
			}
			&.top{
				border-top: 1px solid #4A4A4A;
			}
			.mOrp{
				float: right;
			}
		}
	}
}