.user-order-admin {
  padding: 0 0.15rem 0.3rem;
  table {
    width: 100%;
    font-size: 0.12rem;
    border-collapse:collapse;
    thead {
      td {
        font-weight: 600;
        background: #f5f5f5;
      }
    }
    tbody {

    }
    td {
      text-align: center;
      line-height: 16px;
      padding: 0.02rem;
      border: 1px solid #ccc;
      font-size: 12px;
      
    }
  }
  .nodata {
    font-size: 14px;
    color: #CCC;
    line-height: 0.5rem;
    text-align: center;
  }
  .notice {
    font-size: 0.13rem;
    margin-top: 0.3rem;
    color: #999;
  }
}