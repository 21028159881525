.child-cont-3 {
  padding: 0.38rem 0;
  ul {
    margin-top: 0.5rem;
    list-style-type: none;
    padding: 0;
    li {
      clear: both;
      display: box;
      display: -webkit-box;
      margin-bottom: 0.02rem;
      .img-photo {
        width: 1.65rem;
        height: 0.92rem;
        text-align: center;
        &.cont-1-img1 {
          background: url(../../../assets/img/index/solution_A_01.jpg);
          background-size: 1.65rem;
        }
        &.cont-1-img2 {
          background: url(../../../assets/img/index/solution_A_02.jpg);
          background-size: 1.65rem;
        }
        &.cont-1-img3 {
          background: url(../../../assets/img/index/solution_A_03.jpg);
          background-size: 1.65rem;
        }
        .grey-box {
          font-size: 0.18rem;
          line-height: 0.2rem;
          color: #fff;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: center;
          justify-content: center;
          -ms-flex-direction: column;
          flex-direction: column;
        }
      }
      .right-text {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-direction: column;
        flex-direction: column;
        -moz-box-flex: 1;
        -webkit-box-flex: 1;
        padding-right: 0.2rem;
        margin-left: 0.14rem;
        .content {
          font-size: 0.14rem;
          font-weight: 300;
        }
      }
    }
  }
}
