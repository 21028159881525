.components-corp{
	.selection-1{
		.title-box {
      display: flex;
      font-size: 0.18rem;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.2rem;
      img {
        width: 1rem;
      }
    }
    .img-box {
      padding: 0 0.3rem;
    }
    .img-bottom {
      margin-bottom: 0.5rem;
    }
    .subcontent-ul {
      padding: 0 0.4rem 0 0.5rem;
      margin-bottom: 0.18rem;
      font-size: 0.15rem;
      font-weight: 300;
      list-style: circle;
      margin-bottom: 0.5rem;
      li {
        margin-bottom: 0.06rem;
      }
    }
	}
}
