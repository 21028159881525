.free-week{
	padding: 0.38rem 0 0;
	background: url(../../../assets/img/index/free-week.jpg) no-repeat #2D2D2D;
	background-size: 100%;
	color: #fff;
	height: 2.71rem;
	.btn-demo{
		width: 1.2rem;
		height: 0.375rem;
		line-height: 0.375rem;
		border-radius: 0.03rem;
		font-size: 0.15rem;
		color: #fff;
		background: #333;
		margin: 0.85rem auto 0;
		text-align: center;
		box-shadow: 0 0 3px rgba(0,0,0,0.2);
	}
  .main-title {
    font-size: 0.18rem;
    line-height: 0.26rem;
		text-align: center;
    margin: 0;
    &::after {
      margin: 0;
      height: 0 !important;
    }
  }
	.second-title{
		font-size: 0.12rem;
		text-align: center;
		font-weight: 300;
	}
}