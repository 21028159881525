.page-index{
	background: #f7f7f7;
	.main-title{
		font-size: 0.18rem;
    line-height: 0.26rem;
		text-align: center;
    &::after {
      display: block;
      margin: 0.1rem auto;
      content: '';
      width: 20px;
      height: 2px;
      background: #3e6aa3;
    }
	}
	.second-title{
		font-size: 0.12rem;
		text-align: center;
		font-weight: 300;
	}
	p{
		margin: 0;
	}
}
